<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page" />
    </section>
  </div>
</template>

<script>
import {
  DownloadService,
  SiteService,
  TransactionService as Service
} from '@/services'
import StatusChangeModal from './StatusChange'
import GroupAssignModal from './GroupAssign'

import LogModal from './Log'
import TransactionService from '@/services/transaction.service'

import DepositStatus from "@/components/DepositStatus";

export default {
  name: 'BankTransferWithdrawList',
  data() {
    return {
      table: {
        filter: [
          {
            type: 'input',
            value: 'WITHDRAW',
            hidden: true,
            field: 'type'
          },
          {
            type: 'input',
            value: null,
            field: 'uuid',
            name: 'İşlem ID'
          },
          {
            type: 'input',
            value: null,
            field: 'processId',
            name: 'Process ID'
          },
          {
            type: 'input',
            value: null,
            field: 'iban',
            name: 'IBAN'
          },
          {
            type: 'input',
            value: null,
            field: 'amount',
            name: 'Tutar'
          },
          {
            type: 'input',
            value: null,
            field: 'name',
            name: 'Kullanıcı'
          },
          {
            type: 'enum',
            value: null,
            field: 'status',
            name: 'Durum',
            enumType: 'transactionTypes'
          },
          {
            type: 'datepicker',
            value: [new Date(), new Date()],
            field: 'dates',
            name: 'Talep T.'
          },
          {
            type: 'datepicker',
            value: [null, null],
            field: 'updatedDates',
            name: 'Onay T.'
          }
        ],
        page: {
          title: 'Havele Çekimleri',
          icon: 'cash-multiple',
          actions: [
            {
              title: 'Export',
              type: 'success',
              icon: 'file-excel',
              perm: 'Withdraw.ExcelExport',
              action: () => { this.getExportExcel() }
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'withdrawBankTransfer',
          columns: [
            {
              field: 'status',
              label: 'Durum',
              sortable: true,
              renderComponent: DepositStatus
            },
            {
              field: 'site.name',
              label: 'Site Adı'
            },
            {
              field: 'uuid',
              label: 'İşlem ID',
              sortable: true
            },
            {
              field: 'name',
              label: 'Kullanıcı Adı',
              sortable: true
            },
            {
              field: 'userId',
              label: 'Kullanıcı ID',
              sortable: true
            },
            {
              field: 'total',
              label: 'Ücret',
              sortable: true,
              viewMethod: val => `₺${this.numberFormat(val)}`
            },
							{
								field: "bankName",
								label: "Banka",
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									let logo = process.env.VUE_APP_SITE_URL + "logos/banks/papara.png"
									if (val.withdrawType === 'TETHER' || val.description === "TETHER")
										logo = process.env.VUE_APP_SITE_URL + "logos/"+this.siteName+"/crypto.svg"
									if (val.withdrawType === 'BANK_TRANSFER' || val.withdrawType === 'FOREX' || val.description === "BANK_TRANSFER" || val.description === "FOREX" )
										logo = val.bankList?.logo
									return `<img width="70" style="max-width:70px" src="${logo}" />`
								},
							},
            {
              field: 'accountName',
              label: 'Hesap',
              sortable: true
            },
            {
              field: 'iban',
              label: 'Hesap No',
              sortable: true
            },
            {
              field: 'withdrawGroup',
              label: 'Grup'
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTime)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              width: '100px',
              actions: [
                {
                  title: 'Güncelle',
                  type: 'info',
                  column: 'row',
                  perm: 'Withdraw.StatusChange',
                  icon: 'file-edit-outline',
                  action: data => {
                    if (data.status === 'SUCCESSFUL') {
                      this.$buefy.toast.open({
                        message: 'Onaylanan işlem üzerinde değişiklik yapamazsınız.',
                        type: 'is-warning'
                      })
                      return false
                    }
                    this.openModal({
                      component: StatusChangeModal,
                      props: {
                        withdraw: { ...data },
                      },
                      // close: this.$refs.table.loadAsyncData
                    })
                  }
                },
                {
                  title: 'Loglar',
                  type: 'warning',
                  column: 'row',
                  perm: 'Withdraw.Log',
                  icon: 'format-list-text',
                  action: data => {
                    this.openModal({
                      component: LogModal,
                      props: {
                        withdraw: { ...data },
                      },
                      styles: { width: 1000, height: 800 },
                      // close: this.$refs.table.loadAsyncData
                    })
                  }
                },
                {
                  title: 'Grup Ata',
                  type: 'info',
                  column: 'row',
                  perm: 'Withdraw.GroupAssign',
                  action: data => {
                    this.openModal({
                      component: GroupAssignModal,
                      props: {
                        withdraw: { ...data },
                      },
                      // close: this.$refs.table.loadAsyncData
                    })
                  }
                },
              ]
            }
          ]
        }
      }
    }
  },
  computed: {
    listener () {
      return `transactionWithdrawInserted${this.$store.state.user.group !== 'ALL' ? this.$store.state.user.group : (this.$store.state.user.siteId || '')}`
    },
    updateListener () {
      return `transactionWithdrawUpdated${this.$store.state.user.group !== 'ALL' ? this.$store.state.user.group : (this.$store.state.user.siteId || '')}`
    }
  },
  mounted() {
    if (!this.$store.state.user.siteId) this.getSites()

    setTimeout(() => {
      this.sockets.subscribe(this.updateListener, val => {
        if (!val.status || !val.uuid || this.$refs.table.loading) return
        let newDatas = { WAITING: [], PENDING: [], SUCCESSFUL: [], UNSUCCESSFUL: [] }
        this.$refs.table.data.map((item, key) => {
          if (item.uuid === val.uuid) {
            item.status = val.status
            item.updatedAt = new Date(val.updatedAt)
            item.logs = val.logs
          }
          newDatas[item.status].push(item)
        })

        newDatas['WAITING'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['PENDING'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['SUCCESSFUL'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['UNSUCCESSFUL'].sort((a, b) => a.createdAt - b.createdAt);
        this.$refs.table.data = [...[].concat(newDatas['WAITING']).concat(newDatas['PENDING']).concat(newDatas['SUCCESSFUL']).concat(newDatas['UNSUCCESSFUL'])]
      })
      this.sockets.subscribe(this.listener, val => {
        if(this.$refs.table.pagination.page > 1) return
        val = JSON.parse(val)
        const site = this.$store.state.user.siteId || this.$refs.table.filter[0].value || null
        const uuid = this.$refs.table.filter[2].value || null
        const processId = this.$refs.table.filter[3].value || null
        const iban = this.$refs.table.filter[4].value || null
        const amount = this.$refs.table.filter[5].value || null
        const name = this.$refs.table.filter[6].value || null
        const status = this.$refs.table.filter[8].value || null
        if (
          (!site || val.site.uuid === site) && (!uuid || val.uuid === uuid) && (!processId || val.processId === processId) &&
          (!iban || val.iban === iban) && (!amount || parseFloat(val.total) === parseFloat(amount)) && (!name || val.name === name) && (!status || val.status === status)
          ) {
          if (!this.$refs.table.loading && this.$refs.table.data) this.$refs.table.data.unshift(val)
          // this.$refs.table.data.pop()
        }
        let newDatas = { WAITING: [], PENDING: [], SUCCESSFUL: [], UNSUCCESSFUL: [] }
        this.$refs.table.data.map(item => newDatas[item.status].push(item))
        newDatas['WAITING'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['PENDING'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['SUCCESSFUL'].sort((a, b) => a.createdAt - b.createdAt);
        newDatas['UNSUCCESSFUL'].sort((a, b) => a.createdAt - b.createdAt);
        this.$refs.table.data = [...[].concat(newDatas['WAITING']).concat(newDatas['PENDING']).concat(newDatas['SUCCESSFUL']).concat(newDatas['UNSUCCESSFUL'])]
      })
    }, 500)
  },
  methods: {
    getSites() {
      SiteService.all({ status: 1 }).then(({ data }) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'siteId',
          name: 'Site'
        })
      })
    },
    getExportExcel() {
      let filterParams = {}
      if (this.$refs.table.filter && this.$refs.table.filter.length > 0) {
        this.$refs.table.filter.map(item => {
          if ((item.value !== '' && item.value !== null) || item.hidden === true) {
            if (item.type === 'datepicker') {
              if (typeof item.value === 'object' && item.value.length > 0) {
                if (item.value[0] && item.value[1]) {
                  const startDate = this.getIstanbulDate(item.value[0])
                  const endDate = this.getIstanbulDate(item.value[1], false)
                  filterParams[item.field] = [startDate, endDate]
                }
              } else {
                filterParams[item.field] = item.value
              }
            } else {
              filterParams[item.field] = item.value
            }
          }
        })
      }

      TransactionService.exportWithdrawExcel({ ...filterParams, offset: 180 }).then(({ data: { path, filename } }) => {
        DownloadService.download({ path }).then(res2 => {
          const url = URL.createObjectURL(new Blob([res2.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
      })
    }
  },
  beforeDestroy() {
    this.sockets.unsubscribe(this.updateListener)
    this.sockets.unsubscribe(this.listener)

  }
}
</script>
